import { TAppVersion } from './api/types';

export {};

const VERSION_API = '/api/version/';

window.QUERIES_CACHE_KEY = {
  waffle: {},
  appVersion: {},
};

const map: Record<string, TAppVersion> = {
  prod: TAppVersion.PROD,
  dev: TAppVersion.DEV,
  stage: TAppVersion.STAGE,
};

const getAppVersion = async () => {
  try {
    const response = await fetch(VERSION_API);

    if (response.status === 200) {
      const result = await response.json();
      result.stand = map[result.stand?.toLowerCase?.()] || map['stage'];

      window.QUERIES_CACHE_KEY.appVersion = result;
    }
  } catch (e) {
    console.log('[INITIAL REQUEST] loading app version data error: ', e);
  }
};

Promise.all([getAppVersion()]).then(() => {
  const element = document.querySelector('#QUERIES_CACHE_STATUS') as HTMLElement;
  element.dataset.status = '1';
});
